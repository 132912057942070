import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { AiOutlineMenu } from "react-icons/ai";
import { AiFillCar } from "react-icons/ai";
import myImage from '../images/2015Porsche918Spyder_akwanten_SEO.jpg';
import  { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpened,setMenuOpened] = useState(false);
  
  return(
    
    <>
<header>
<a href="/" className="logo"><AiFillCar/></a>
<div className="icons">

<div id="menu-bars"  onClick={()=>setMenuOpened(true)}>
            <AiOutlineMenu />
  </div>
  {(menuOpened===false && mobile===true)?(
  <div
  style={{
    backgroundColor: "var(--appColor)",
    padding:"1rem",
    borderRadius:"5px",}}
  onClick={()=>setMenuOpened(true)}
  >
  </div>
):(
  <ul style={{listStyle:"none"}} className={`icons ${mobile ? "heading-menu" : "navbar"}`}>
<li><Link className="active" onClick={()=>setMenuOpened(false)} to='/'span={true}smooth={true}>Home</Link></li>
<li><Link  onClick={()=>setMenuOpened(false)} to='/services'span={true}smooth={true}>Cars</Link></li>
<li><Link  onClick={()=>setMenuOpened(false)} to='/about'span={true}smooth={true}>About</Link></li>        
<li><Link  onClick={()=>setMenuOpened(false)} to='/price'span={true}smooth={true}>Price</Link></li>        
<li><Link onClick={()=>setMenuOpened(false)} to='/projects'span={true}smooth={true}>Review</Link></li>
<li><Link onClick={()=>setMenuOpened(false)} to='/contact'span={true}smooth={true}>Contact</Link></li>
</ul>)}

</div>


</header>
{/* <!-- header section ends-->

<!-- home section starts  --> */}
<section className="home" id="home">
{/* <!-- swiper-xxx are all swiper built-in classnames --> */}
<div className="swiper-container home-slider">

    <div className="swiper-wrapper wrapper">

        <div className="swiper-slide slide">
            <div className="content">
                <span>our special car</span>
                <h3>Forbes</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit natus dolor cumque?</p>
                <Link to="/contact" className="btn">Book now</Link>
            </div>
            <div className="image">
                <img src={myImage} alt=""/>
            </div>
        </div>
       
    </div>
{/* <!-- swiper buttons --> */}
    <div className="swiper-pagination"></div>
</div>
</section>
{/* <!-- home section ends --> */}





    </>

  )
}

export default Navbar;




    {/* <div className="header" id="topheader">

    <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
    <div className="container-fluid">
      <NavLink className="navbar-brand font-weight-bold text-white" to="/"><img src="https://www.pngmart.com/files/22/Car-Logo-PNG-Transparent.png" alt="logo" class="logo"/></NavLink>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
    
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto text-uppercase">
          <li className="nav-item active">
            <NavLink className="nav-link" to="/">Home <span className="sr-only">(current)</span></NavLink>
          </li>
       
          <li className="nav-item">
            <NavLink className="nav-link" to="/services">Service</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/price">Price</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/about">About</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/contact">Contact</NavLink>
          </li> */}
          {/* <li className="nav-item">
            <NavLink className="nav-link" to="/projects">Projects</NavLink>
          </li> */}
    
        {/* </ul>
      
      </div>
    </div>
    </nav> */}
          {/* <section className="header-section">
            <div className="center-div">
                <h1 className="font-weight-bold">WE ARE THE BUILDERS</h1>
                <p>WE CREATE THE WORLD BEST BUILDINGS</p>
                <div className="header-buttons">
                    <a href="/about">AboutUs</a>
                    <a href="/contact">Contact</a>
                </div>
            </div>
          </section> */}

    {/* </div> */}