import React , {useState} from "react";
import { NavLink , useNavigate } from "react-router-dom";

const Signup = () => {

    const navigate = useNavigate();

    const[user,setUser]=useState({
        name:"", email:"", phone:"", work:"" , password:"", confirm_password:""})

        let name,value;

        const handleInputs = (e) =>{
            console.log(e);
            name = e.target.name;
            value = e.target.value;

            setUser({...user,[name]:value});
        }

        // fetch api start //

        const PostData = async (e)=>{

          console.log("hello q")

          //e.preventdefault();

            const {name , email , phone , work , password , confirm_password} = user;

            console.log("hello")
            const res =  fetch("http://localhost:5000/register",{
                method:"POST",
                headers:{
                    "Content-Type" : "application/json"
                },
                body:JSON.stringify({
                  // body.name = name;
                    name , email , phone , work , password , confirm_password
                })
                           

            });
           console.log("registration ", res)

            const data =  res.json();
            console.log("registration data", data)

            if(res.status === 422 || !data){
                window.alert("Error Occured");
                console.log("Error Occured");
            }else{
                window.alert("Succesfully Send");
                console.log("Succesfully Send");

                // navigate.push("/login");
            }

            // fetch api end
        }

  return(
    
    <>
                  <div>
                    <button className="b1" type="submit"name="signup" id="signup" onClick={PostData} ><a href="PostData"></a>Register</button>
                  </div>


  <div className="main">
    <form method="POST">
        <div id="name">
            <h2 className="name">Name</h2>
            <input className="firstname" type="text" name="name" id="firstname" autoComplete="off" value={user.name} onChange={handleInputs} /><br />
        </div>

          <div>
            <h2 className="name">Profession</h2>
              <input className="work" type="text" name="work" id="work" autoComplete="off" value={user.work}  onChange={handleInputs} />
          </div>

            <div>
              <h2 className="name">Email</h2>
                <input className="email" type="text" name="email" id="email" autoComplete="off" value={user.email}  onChange={handleInputs} />
            </div>
        
              <div>
                <h2 className="name">Phone</h2>
                  <input className="number" type="number" name="phone" id="number" autoComplete="off" value={user.phone}  onChange={handleInputs} />
              </div>   
         
                <div>
                  <h2 className="name">Password</h2>
                    <input className="password" type="password" name="password" id="password" autoComplete="off" value={user.password}  onChange={handleInputs} />      
                      <h2 className="name">Confirm Password</h2>
                        <input className="confirm_password" type="password" name="confirm_password" id="confirm_password" autoComplete="off" value={user.confirm_password} 
                        onChange={handleInputs} />      
                </div>
                  
                  <div>
                    <button className="b1" type="submit"name="signup" id="signup" onClick={PostData} >Register</button>
                    <NavLink to="/login"><button className="b2">Already Register</button></NavLink>
                  </div>
                   
    </form>
  </div>
          

          
    </>
           
  )
}

export default Signup;