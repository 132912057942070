import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import {NavLink} from "react-router-dom";
// import logo from "../images/register.svg"
import { AiFillFacebook } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { AiFillTwitterCircle } from "react-icons/ai";

const Footer = () => {
  return(
    
    <>
    {/* <!-- footer section starts  --> */}

<section className="footer">

    <div className="box-container">

        <div className="box">
            <h3>locations</h3>
            <a href="#">india</a>
            <a href="#">Pakistan</a>
            <a href="#">russia</a>
            <a href="#">USA</a>
            <a href="#">france</a>
        </div>

        <div className="box">
            <h3>quick links</h3>
            <a href="#">home</a>
            <a href="#">about</a>
            <a href="#">menu</a>
            <a href="#">reivew</a>
            <a href="#">order</a>
        </div>

        <div className="box">
            <h3>contact info</h3>
            <a href="#">+123-456-7890</a>
            <a href="#">+111-222-3333</a>
            <a href="#">ABC@gmail.com</a>
            <a href="#">XYZ@gmail.com</a>
            <a href="#">karachi, pakistan - 400104</a>
        </div>

        <div className="box">
            <h3>follow us</h3>
            <a href="#">facebook</a>
            <a href="#">twitter</a>
            <a href="#">instagram</a>
            <a href="#">linkedin</a>
        </div>

    </div>


</section> 

{/* <!-- footer section ends -->

{/* <!-- loader part  --> */}
{/* <div className="loader-container">
    <img src="images/loader.gif" alt=""/>
</div> */}

    
    </>

  )}

  export default Footer;



  {/* <!-- ********************************** newsletter start************************************************ --> */}

{/* 
    <section className="newsletter"id="newsletterid">
  <div className="container headings text-center">
    <h1 className="text-center font-weight-bold">CAR RENTAL COMPANY</h1>
  </div>

</section>  */}


{/* <!-- **********************************newsletter ends************************************************ --> */}


{/* <!-- ******************************************footer start******************************** --> */}


{/* <footer className="footersection" id="footerid">
  <div className="container">
    <div className="row">
      <div className="col-lg-4 col-md-6 col-12 footer-div">
        <div>
          <h3>ABOUT CAR RENTAL</h3>
          <p>Car Rental offer a wide selection of vehicles to meet all of your transportation needs. Whether you're looking for a compact car for a city trip, a spacious SUV for a family vacation, or a luxury vehicle for a special occasion, we have something for everyone. Our fleet is regularly updated with the latest models, so you can always be sure you're getting a reliable and high-quality vehicle. We also offer competitive pricing and flexible rental options, including daily, weekly, and monthly rentals. Plus, our 24/7 customer service team is always available to assist you with any questions or concerns. Trust us for all your car rental needs.</p>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 col-12 footer-div text-center">
        <div>

          <h3>SOCIAL LINKS</h3>
          <li><NavLink to="#"><AiFillFacebook/></NavLink></li>
          <li><NavLink to="#"><AiFillInstagram/></NavLink></li>
          <li><NavLink to="#"><AiFillLinkedin/></NavLink></li>
          <li><NavLink to="#"><AiFillTwitterCircle/></NavLink></li>

        </div>
       
      </div>

      <div className="col-lg-4 col-md-12 col-12 footer-div">
        <div>
          <h3>CONTACT US</h3>
          <p>24/7 SUPPORT : 03312541657</p>
          <p>MAIL : carrental34@gmail.com</p>
          <p>SUBSCRIBE TO OUR CHANNEL</p>
          
            <div className="container newsletter-main">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="input-group mb-3">
                    <input type="text" className="form-control news-input" placeholder="Your Email"/>
                    <div className="input-group-append">
                      <span className="input-group-text">Subscribe</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>


    </div>

    <div className="mt-5 text-center">
      <p>Copyright @2023 All rights reserved/This web is made for business.</p>
    </div>

  </div>





</footer>

 */}

{/* <!-- ******************************************footer end******************************************* --> */}




