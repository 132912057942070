import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import About from "./components/About";
import Services from "./components/Services"
import Contact from "./components/Contact";
import Signup from "./components/Signup";
import Errorpage from "./components/Errorpage";
import Price from "./components/Price";
import Footer from "./components/Footer";
import Projects from "./components/Projects";
import { Route, Routes } from "react-router-dom";
import "./App.css";


const App = () => {
  return(
    <>
        <Navbar/>


    <Routes>

          <Route exact path="/" element={<Home />}/>
          <Route exact path="/about" element = {<About />} />
          <Route exact path="/services" element = {<Services />} />
          <Route exact path="/price" element = {<Price />} />
          <Route exact path="/projects" element = {<Projects />} />          
          <Route exact path="/footer" element = {<Footer />} />
          <Route exact path="/contact" element = {<Contact />} />
          <Route exact path="/signup" element = {<Signup />} />
          <Route path="*" element = {<Errorpage />} />


    </Routes>

<Footer/>


    </>

  )
}

export default App;
