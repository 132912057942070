import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { AiFillStar } from "react-icons/ai";
import { BsStarHalf } from "react-icons/bs";
import myImage from '../images/maxresdefault.jpg';

const Services = () => {
  return(
    
          <>
        

    {/* // <!-- dishes section starts  --> */}
    <section className="dishes" id="dishes">
    
        <h3 className="sub-heading"> our service </h3>
        <h1 className="heading"> popular cars </h1>
    
        <div className="box-container">
    {/* <!-- DISH 1 --> */}
            <div className="box">
                
                <img src={myImage} alt=""/>
                <h3>special car</h3>
                <div className="stars">
                <a href="/projects"><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/><BsStarHalf/></a>
                </div>
                <span>$15.99</span>
                <a href="#order" className="btn">Book Now</a>
            </div>
    {/* <!-- DISH 2 --> */}
            <div className="box">
                
                <img src={myImage}lt=""/>
                <h3>special car</h3>
                <div className="stars">
                <a href="/projects"><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/><BsStarHalf/></a>
                </div>
                <span>$15.99</span>
                <a href="#order" className="btn">Book Now</a>
            </div>
          
    {/* <!-- DISH 3 --> */}
            <div className="box">
                <img src={myImage} alt=""/>
                <h3>special car</h3>
                <div className="stars">
                <a href="/projects"><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/><BsStarHalf/></a>
                </div>
                <span>$15.99</span>
                <a href="#order" className="btn">Book Now</a>
            </div>
    {/* <!-- DISH 4 --> */}
            <div className="box">
                <img src={myImage} alt=""/>
                <h3>special car</h3>
                <div className="stars">
                <a href="/projects"><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/><BsStarHalf/></a>
                </div>
                <span>$15.99</span>
                <a href="#order" className="btn">Book Now</a>
            </div>
    {/* <!-- DISH 5 --> */}
            <div className="box">
                
                <img src={myImage} alt=""/>
                <h3>special car</h3>
                <div className="stars">
                <a href="/projects"><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/><BsStarHalf/></a>
                </div>
                <span>$15.99</span>
                <a href="#order" className="btn">Book Now</a>
            </div>
    
        </div>
    </section>
    {/* <!-- dishes section ends --> */}

          </>
  )
  }

  export default Services;



      
       {/* <!-- *******************************services starts**************************** --> */}



// <section className="header-extra-div">
//  <div className="container">
//  <h1 className="text-center font-weight-bold">RENTAL SERVICES</h1>
//    <div className="row">
//      <div className="extra-div col-lg-4 col-md-4 col-12">
//          <a href="/contact"><BsFillTelephoneFill/></a>
//          <h2> 24 / 7 SUPPORT </h2>
//          <p> Feel Free To Contact US</p>
//          <p>24/7 Support</p>
//      </div>
   
//      <div className=" extra-div col-lg-4 col-md-4 col-12">
//        <a href="/projects"><AiFillEdit/></a>
//        <h2> We Provide Easier & Faster Bookings </h2> 
//        <p>We focus on the good experience of our customers so we try to provide them with the best possible ways of booking a car.
//          We try to keep the booking process simple and easy and to allow customers to complete their booking with just 
//         a few clicks. We give accurate information which makes the booking process easy and fast.!</p>
//    </div>   
  
//    <div className="extra-div col-lg-4 col-md-4 col-12">
//      <a href="/projects"><AiFillCar/></a>
//      <h2> Pickup Service </h2>
//      <p> Arrange for your car to be picked up by you wherever it's convenient for you. 
//       Simply enter an address of your choice and arrange for the car to be picked up there, with no fuss. 
//       We keep this whole process simple and smooth going for our customers as the main goal of our is customer satisfaction. ! </p>
//  </div>

// </div>

// </div>


// </section>



{/* <!-- *******************************services ends**************************** --> */}