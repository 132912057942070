import React from "react";
import { GrDeliver } from "react-icons/gr";
import { FiDollarSign } from "react-icons/fi";
import { BiSupport } from "react-icons/bi";
import myImage from '../images/BeFunky-collage-1.jpg'


const About = () => {
  return(
    
    <>

{/* <!-- about section starts  --> */}
<section className="about" id="about">

    <h3 className="sub-heading"> about us </h3>
    <h1 className="heading"> why choose us? </h1>

    <div className="row">

        <div className="image">
            <img src={myImage} alt=""/>
        </div>

        <div className="content">
            <h3>best car service</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore, sequi corrupti corporis quaerat voluptatem ipsam neque labore modi autem, saepe numquam quod reprehenderit rem? Tempora aut soluta odio corporis nihil!</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam, nemo. Sit porro illo eos cumque deleniti iste alias, eum natus.</p>
            <div className="icons-container">
                <div className="icons">
                <GrDeliver/>
                <span>free delivery</span>
                </div>
                <div className="icons">
                    <FiDollarSign/>
                    <span>easy payments</span>
                </div>
                <div className="icons">
                    <BiSupport/>
                    <span>24/7 service</span>
                </div>
            </div>
        </div>

    </div>

</section>
{/* <!-- about section ends --> */}

    </>

  )
}

export default About;


{/* 
<section className="happyclients"id="happyclients">
  <div className="container headings text-center">
    <h1 className="text-center font-weight-bold">ABOUT RENTAL</h1>
    <h3 className="text-capitalize pt-1">Our Qualified Captains</h3>
  </div>

</section>
  
    {/* <!-- The slideshow --> */}
    
        {/* <div className="row">
          <div className="col-lg-4 col-md-4 col-12">
            <div className="box">
              <a href="/">
                <img src="https://atpclap.files.wordpress.com/2019/04/driver.jpg"alt="image" className="img-fluidi img-thumbnail"/>
              </a>
              <p className="m-4"><h1>CAPTAIN</h1></p>
                <h1>JOHN</h1>
                <h1>Morning Shift</h1>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-12">
            <div className="box">
              <a href="/"><img src="https://atpclap.files.wordpress.com/2019/04/driver.jpg"alt="image"className="img-fluidi img-thumbnail"/></a>
              <p className="m-4"><h1>CAPTAIN</h1></p>
                <h1>ABARAHAM</h1>
                <h1>Evening Shifting</h1>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-12">
            <div className="box">
              <a href="/"><img src="https://atpclap.files.wordpress.com/2019/04/driver.jpg"alt="image"className="img-fluidi img-thumbnail"/></a>
              <p className="m-4"><h1>CAPTAIN</h1></p>
                <h1>SMITH</h1>
                <h1>Night Shift</h1>
            </div>
          </div>

        </div> */}



