import React,{useState} from "react";
import { NavLink,useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";

const Contact = () => {

    const navigate = useNavigate();

    const[user,setUser]=useState({
        name:"", email:"", phone:"", work:"" , password:"", confirm_password:""})

        let name,value;

        const handleInputs = (e) =>{
            console.log(e);
            name = e.target.name;
            value = e.target.value;

            setUser({...user,[name]:value});
        }

        // fetch api start //

        const PostData = async (e)=>{

          // console.log("hello q")

          //e.preventdefault();

            const {name , email , phone , work , password , confirm_password} = user;

            // console.log("hello")
            const res = await fetch("http://localhost:5000/register",{
                method:"POST",
                headers:{
                    "Content-Type" : "application/json"
                },
                body:JSON.stringify({
                  // body.name = name;
                    name , email , phone , work , password , confirm_password
                })
                           

            });
           console.log("registration ", res)

            const data = await res.json();
            console.log("registration data", data)

            if(data.status === 422 || !data){
                window.alert("Error Occured");
                console.log("Error Occured");
            }else{
                window.alert("Succesfully Send");
                console.log("Succesfully Send");

                // navigate.push("/login");
            }

            // fetch api end
        }






  

  return(
  <>


<section className="contactus"id="contactid">
  <div className="container headings text-center">
    <h1 className="text-center font-weight-bold">Contact Us</h1>
    <p className="text-capitalize pt-1">we are here to help and answer any question you might have.We look forward to hearing from you</p>
  </div>
  <div className="container">
    <div className="row">
      <div className="col-lg-8 col-md-8 col-10 offset-lg-2 offset-md-2 offset-1">
        <form method="#">
          <div id="name" className="form-group">
            <input type="text" className="form-control" placeholder="Enter Your Name" id="firstname" required autocomplete="off"  onChange={handleInputs}/>
          </div>
          </form>
          <form method="POST">
            <div className="form-group">
              <input type="email" className="form-control" placeholder="Enter Your Email" id="email" required autocomplete="off" onChange={handleInputs}/>
            </div>
          </form>
          
          <form method="#">
                <div className="form-group">
                  <h2 className="name"></h2>
                    <input className="form-control" type="password"placeholder="Enter Your Password" rows="4" id="password" autoComplete="off"  onChange={handleInputs} />      
                      <h2 className="name"></h2>
                        <input className="form-control" type="password" placeholder="confirm_password" id="confirm_password" autoComplete="off" 
                        onChange={handleInputs} />      
                </div>
          </form>

            <form method="">
              <div className="form-group">
                <input type="number" className="form-control" placeholder="Enter Your Mobile Number" id="mobile" required autocomplete="off" onChange={handleInputs} />
              </div>

              <div className="form-group">
                <textarea className="form-control" placeholder="Enter Your Message" rows="4" id="comment" onChange={handleInputs}></textarea>
              </div>
            <div className="d-flex justify-content-center form-button">
              <button type="submit" className="btn btn-primary"onClick={PostData}>Submit</button>
            </div>
        </form>


      </div>

    </div>

  </div>

  </section>

  </>

  )
}

export default Contact;