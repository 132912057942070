import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { AiFillStar } from "react-icons/ai";
import { BsStarHalf } from "react-icons/bs";
import myImage from '../images/fastest-cars-world-2023-list-ranking-luxe-digital.jpg'


const Price = () => {
    return(
      
      <>

{/* <!-- menu section starts  --> */}
<section className="menu" id="menu">

    <h1 className="heading"> our Iventory </h1>
    <h3 className="sub-heading"> speciality </h3>

    <div className="box-container">

        <div className="box">
            <div className="image">
                <img src={myImage} alt=""/>
            </div>
            <div className="content">
                <div className="stars">
                <a href="/projects"><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/><BsStarHalf/></a>
                </div>
                <h3>Awesome Car</h3>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi, accusantium.</p>
                <a href="#" className="btn">Book Now</a>
                <span className="price">$12.99</span>
            </div>
        </div>

        <div className="box">
            <div className="image">
                <img src={myImage} alt=""/>
            </div>
            <div className="content">
                <div className="stars">
                <a href="/projects"><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/><BsStarHalf/></a>
                </div>
                <h3>Awesome Car</h3>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi, accusantium.</p>
                <a href="#" className="btn">Book Now</a>
                <span className="price">$12.99</span>
            </div>
        </div>

        <div className="box">
            <div className="image">
                <img src={myImage} alt=""/>
            </div>
            <div className="content">
                <div className="stars">
                <a href="/projects"><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/><BsStarHalf/></a>
                </div>
                <h3>Awesome Car</h3>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi, accusantium.</p>
                <a href="#" className="btn">Book Now</a>
                <span className="price">$12.99</span>
            </div>
        </div>

        <div className="box">
            <div className="image">
                <img src={myImage} alt=""/>
            </div>
            <div className="content">
                <div className="stars">
                <a href="/projects"><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/><BsStarHalf/></a>
                </div>
                <h3>Awesome Car</h3>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi, accusantium.</p>
                <a href="#" className="btn">Book Now</a>
                <span className="price">$12.99</span>
            </div>
        </div>

        <div className="box">
            <div className="image">
                <img src={myImage} alt=""/>
            </div>
            <div className="content">
                <div className="stars">
                <a href="/projects"><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/><BsStarHalf/></a>
                </div>
                <h3>Awesome Car</h3>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi, accusantium.</p>
                <a href="#" className="btn">Book Now</a>
                <span className="price">$12.99</span>
            </div>
        </div>

        <div className="box">
            <div className="image">
                <img src={myImage} alt=""/>
            </div>
            <div className="content">
                <div className="stars">
                <a href="/projects"><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/><BsStarHalf/></a>
                </div>
                <h3>Awesome Car</h3>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi, accusantium.</p>
                <a href="#" className="btn">Book Now</a>
                <span className="price">$12.99</span>
            </div>
        </div>

        <div className="box">
            <div className="image">
                <img src={myImage} alt=""/>
            </div>
            <div className="content">
                <div className="stars">
                <a href="/projects"><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/><BsStarHalf/></a>
                </div>
                <h3>Awesome Car</h3>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi, accusantium.</p>
                <a href="#" className="btn">Book Now</a>
                <span className="price">$12.99</span>
            </div>
        </div>

        <div className="box">
            <div className="image">
                <img src={myImage} alt=""/>
            </div>
            <div className="content">
                <div className="stars">
                <a href="/projects"><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/><BsStarHalf/></a>
                </div>
                <h3>Awesome Car</h3>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi, accusantium.</p>
                <a href="#" className="btn">Book Now</a>
                <span className="price">$12.99</span>
            </div>
        </div>

        <div className="box">
            <div className="image">
                <img src={myImage} alt=""/>
            </div>
            <div className="content">
                <div className="stars">
                <a href="/projects"><AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/><BsStarHalf/></a>
                </div>
                <h3>Awesome Car</h3>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi, accusantium.</p>
                <a href="#" className="btn">Book Now</a>
                <span className="price">$12.99</span>
            </div>
        </div>

    </div>

</section>
{/* <!-- menu section ends --> */}






      </>
    )}
    export default Price;




    
{/* <!--*********************************** our price  start ***************************************--> */}



{/* <section className="pricing" id="pricingdiv">
  <div className="container headings text-center">
    <h1 className="text-center font-weight-bold text-dark">CAR RENTAL PRICES</h1>
  </div>
  <div className="container">
    <div className="row">
      <div className="col-lg-4 col-12">
        <div className="card text-center">
          <div className="card-header"  >BASIC</div>
          <div className="card-body">
            <li>$<span className="money">20</span>/CAR MINI</li>
            <li>WHITHOUT AC</li>
            <li>UNDER THE BUDGET</li>
            <li>AFFORDABLE</li>
            <li>SMALL CAPACITY</li>
          </div>
          <div className="card-footer">
          <NavLink to="/contact">BOOK</NavLink>

        </div>
        </div>
      </div>
      <div className="col-lg-4 col-12 card-second">
        <div className="card text-center">
          <div className="card-header">STANDARD</div>
          <div className="card-body">
            <li>$<span className="money">40</span>/CAR GO</li>
            <li>WITH AC</li>
            <li>MEDIUM TO BUDGET</li>
            <li>AFFORDABLE</li>
            <li>MEDIUM CAPACITY</li>
          </div>
          <div className="card-footer">
          <NavLink to="/contact">BOOK</NavLink>

        </div>
        </div>
      </div>
      <div className="col-lg-4 col-12">
        <div className="card text-center">
          <div className="card-header">UNLIMITED</div>
          <div className="card-body">
            <li>$<span className="money">60</span>/CAR +</li>
            <li>WITH AC</li>
            <li>EXPENSIVE RIDE</li>
            <li>WITH DRIVER</li>
            <li>HIGH CAPACITY</li>
          </div>
          <div className="card-footer">
          <NavLink to="/contact">BOOK</NavLink>

        </div>
        </div>
      </div>
    </div>
  </div>
</section> */}


{/* <!--*********************************** our price ends ***************************************--> */}