import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { AiFillStar } from "react-icons/ai";
import { BsStarHalf } from "react-icons/bs";
import myImage from '../images/12.jpg'
import myImage1 from '../images/13.jpg'
import myImage2 from '../images/14.jpg'
import myImage3 from '../images/15.jpg'

const Projects = () => {

  return(
          <>
      {/* // <!-- review section starts  --> */}
    <section className="review" id="review">
    {/* <!-- using slider plugin here again --> */}
        <h3 className="sub-heading"> customer's review </h3>
        <h1 className="heading"> what they say </h1>
    
        <div className="swiper-container review-slider">
    
            <div className="swiper-wrapper">
    
                <div className="swiper-slide slide">
                    <i className="fas fa-quote-right"></i>
                    <div className="user">
                        <img src={myImage} alt=""/>
                        <div className="user-info">
                            <h3>john deo</h3>
                            <div className="stars">
                            <AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/><BsStarHalf/>
                            </div>
                        </div>
                    </div>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit fugiat consequuntur repellendus aperiam deserunt nihil, corporis fugit voluptatibus voluptate totam neque illo placeat eius quis laborum aspernatur quibusdam. Ipsum, magni.</p>
                </div>
    
                <div className="swiper-slide slide">
                    <i className="fas fa-quote-right"></i>
                    <div className="user">
                        <img src={myImage1} alt=""/>
                        <div className="user-info">
                            <h3>john deo</h3>
                            <div className="stars">
                            <AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/><BsStarHalf/>
                            </div>
                        </div>
                    </div>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit fugiat consequuntur repellendus aperiam deserunt nihil, corporis fugit voluptatibus voluptate totam neque illo placeat eius quis laborum aspernatur quibusdam. Ipsum, magni.</p>
                </div>
    
                <div className="swiper-slide slide">
                    <i className="fas fa-quote-right"></i>
                    <div className="user">
                        <img src={myImage2} alt=""/>
                        <div className="user-info">
                            <h3>john deo</h3>
                            <div className="stars">
                            <AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/><BsStarHalf/>
                            </div>
                        </div>
                    </div>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit fugiat consequuntur repellendus aperiam deserunt nihil, corporis fugit voluptatibus voluptate totam neque illo placeat eius quis laborum aspernatur quibusdam. Ipsum, magni.</p>
                </div>
    
                <div className="swiper-slide slide">
                    <i className="fas fa-quote-right"></i>
                    <div className="user">
                        <img src={myImage3} alt=""/>
                        <div className="user-info">
                            <h3>john deo</h3>
                            <div className="stars">
                            <AiFillStar/><AiFillStar/><AiFillStar/><AiFillStar/><BsStarHalf/>
                            </div>
                        </div>
                    </div>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit fugiat consequuntur repellendus aperiam deserunt nihil, corporis fugit voluptatibus voluptate totam neque illo placeat eius quis laborum aspernatur quibusdam. Ipsum, magni.</p>
                </div>
    
            </div>
    
        </div>
        
    </section>
    {/* <!-- review section ends --> */}

          </>
  )}

  export default Projects;




    {/* <section className="aboutd my-5"id="aboutdiv">
            <div className="my-5">
                <h2 className="text-center font-weight-bold">PROJECTS</h2>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-12">
                        <div className="card">
                            <img className="card-img-top" src="https://www.planning.nsw.gov.au/-/media/Images/DPE/Tiles/Tile-Images-430x278/buildings-tile-430x278.jpg" alt=""/>
                                <div className="card-body text-center">
                                    <NavLink to="/" className="btn btn-primary">See Profile</NavLink>
                                </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-12">
                        <div className="card">
                            <img className="card-img-top" src="https://www.planning.nsw.gov.au/-/media/Images/DPE/Tiles/Tile-Images-430x278/buildings-tile-430x278.jpg" alt=""/>
                                <div className="card-body text-center">

                                    <NavLink to="/" className="btn btn-primary">See Profile</NavLink>
                                </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-12">
                        <div className="card">
                            <img className="card-img-top" src="https://www.planning.nsw.gov.au/-/media/Images/DPE/Tiles/Tile-Images-430x278/buildings-tile-430x278.jpg" alt=""/>
                                <div className="card-body text-center">
                                    <NavLink to="/" className="btn btn-primary">See Profile</NavLink>
                                </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>   */}